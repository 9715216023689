window.debounce = function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

window.menuSetActiveItem = function menuSetActiveItem(item) {
  const navigator = document.getElementById("Nav-Indicator");
  navigator.style.top = `${item * 81 + 3}px`;

  // Add active to li item
  const liItems = document.querySelectorAll("li.Nav-Item");
  liItems.forEach(function (element) {
    if (element.dataset.idx == item) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  });
};

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Fade in element based on viewport visibility
   * */
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  function observerCallback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        // fade in observed elements that are in view
        entry.target.classList.replace("fadeOut", "fadeIn");
      } else {
        // fade out observed elements that are not in view
        entry.target.classList.replace("fadeIn", "fadeOut");
      }
    });
  }

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  const fadeElms = document.querySelectorAll(".fade");
  fadeElms.forEach(function (el) {
    el.classList.add("fadeOut");
    observer.observe(el);
  });
});

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Fade in images on load
   * */
  const images = document.getElementsByTagName("img");

  for (let image of images) {
    image.classList.add("loading");
    // Watch image loading
    let img = new window.Image();
    img.src = image.src;
    img.onload = function () {
      image.classList.replace("loading", "loaded");
    };
  }
});
